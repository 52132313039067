exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-contact-us-js": () => import("./../../../src/pages/about-us/contact-us.js" /* webpackChunkName: "component---src-pages-about-us-contact-us-js" */),
  "component---src-pages-about-us-our-partners-js": () => import("./../../../src/pages/about-us/our-partners.js" /* webpackChunkName: "component---src-pages-about-us-our-partners-js" */),
  "component---src-pages-about-us-who-we-are-js": () => import("./../../../src/pages/about-us/who-we-are.js" /* webpackChunkName: "component---src-pages-about-us-who-we-are-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-what-do-we-do-education-js": () => import("./../../../src/pages/what-do-we-do/education.js" /* webpackChunkName: "component---src-pages-what-do-we-do-education-js" */),
  "component---src-pages-what-do-we-do-evangelism-js": () => import("./../../../src/pages/what-do-we-do/evangelism.js" /* webpackChunkName: "component---src-pages-what-do-we-do-evangelism-js" */),
  "component---src-pages-what-do-we-do-hiv-aids-prevention-js": () => import("./../../../src/pages/what-do-we-do/hiv-aids-prevention.js" /* webpackChunkName: "component---src-pages-what-do-we-do-hiv-aids-prevention-js" */),
  "component---src-pages-what-do-we-do-poverty-alleviation-js": () => import("./../../../src/pages/what-do-we-do/poverty-alleviation.js" /* webpackChunkName: "component---src-pages-what-do-we-do-poverty-alleviation-js" */),
  "component---src-pages-what-do-we-do-sports-and-culture-js": () => import("./../../../src/pages/what-do-we-do/sports-and-culture.js" /* webpackChunkName: "component---src-pages-what-do-we-do-sports-and-culture-js" */),
  "component---src-pages-what-do-we-do-volunteer-works-js": () => import("./../../../src/pages/what-do-we-do/volunteer-works.js" /* webpackChunkName: "component---src-pages-what-do-we-do-volunteer-works-js" */),
  "component---src-pages-where-we-work-js": () => import("./../../../src/pages/where-we-work.js" /* webpackChunkName: "component---src-pages-where-we-work-js" */)
}

